import { HeaderPrimaryActionPortal } from 'core/elements/header/portals'
import DomainPickList from './DomainPickList'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import Theme from 'core/themes/model'
import { isSystemAdminUser } from 'core/utils/helpers'
import Typography from '@material-ui/core/Typography'

interface DomainFilterProps {
  value: string
  onChange: (value: string) => void
}

export default function DomainFilter({ value, onChange }: DomainFilterProps) {
  const classes = useStyles()

  if (!isSystemAdminUser()) return null

  return (
    <HeaderPrimaryActionPortal>
      <div className={classes.root}>
        <Typography variant="body2">Domain</Typography>
        <DomainPickList value={value} onChange={onChange} label={null} />
      </div>
    </HeaderPrimaryActionPortal>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    width: '250px',

    '& > div': {
      width: '100%',
    },
  },
}))

import MyAccountHeader from 'account/components/secondaryHeaders/MyAccountHeader'
import SsoManagementPage from 'account/components/ssoManagement/SsoManagementPage'
import { AppPlugins, userAccountPrefix } from 'app/constants'
import Plugin from 'core/plugins/plugin'
import SystemStatusPage from './components/system-status'
import CustomThemePage from './components/theme/CustomThemePage'
import UserSettingsIndexPage from './components/user-settings/UserSettingsIndexPage'
import AddUserGroupPage from 'account/components/userManagement/groups/AddUserGroupPage'
import UserGroupsListPage from 'account/components/userManagement/groups/UserGroupsListPage'
import UserRolesListPage from 'account/components/userManagement/roles/UserRolesListPage'
import TenantDetailsPage from 'account/components/userManagement/tenants/TenantDetailsPage'
import DomainsListPage from 'account/components/userManagement/domains/DomainsListPage'
import TenantsListPage from 'account/components/userManagement/tenants/TenantsListPage'
import AddUserPage from 'account/components/userManagement/users/AddUserPage'
import UsersListPage from 'account/components/userManagement/users/UsersListPage'
import { isSystemAdminUser } from 'core/utils/helpers'
import ApiServicesPage from 'k8s/components/apiAccess/ApiServicesPage'

const myAccountPlugin = new Plugin(AppPlugins.MyAccount, 'Settings', userAccountPrefix, 'cog')
myAccountPlugin.registerSecondaryHeader(MyAccountHeader)

myAccountPlugin.registerRoutes([
  {
    name: 'System Status',
    link: {
      path: '/status',
      exact: true,
    },
    component: SystemStatusPage,
  },
  {
    name: 'User Settings',
    link: {
      path: '/settings/:tab?',
      exact: true,
      default: true,
      defaultParams: { tab: 'profile' },
    },
    component: UserSettingsIndexPage,
  },
  {
    name: 'Domains',
    requiredRoles: 'admin',
    link: {
      path: '/access/domains/(add)?',
      exact: true,
    },
    component: DomainsListPage,
  },
  {
    name: 'Tenants',
    requiredRoles: 'admin',
    link: {
      path: '/access/tenants/(add)?',
      exact: true,
    },
    component: TenantsListPage,
  },
  {
    name: 'Tenant Details',
    requiredRoles: 'admin',
    link: { path: '/access/tenants/:id/:tab?', exact: true },
    component: TenantDetailsPage,
  },
  {
    name: 'Users',
    requiredRoles: 'admin',
    link: {
      path: '/access/users/(add)?',
      exact: true,
    },
    component: UsersListPage,
  },
  {
    name: 'Add User',
    requiredRoles: 'admin',
    link: { path: '/access/users/add', exact: true },
    component: AddUserPage,
  },
  {
    name: 'Roles',
    requiredRoles: 'admin',
    link: {
      path: '/access/roles/(add)?',
      exact: true,
    },
    component: UserRolesListPage,
  },
  {
    name: 'Groups',
    requiredRoles: 'admin',
    link: {
      path: '/access/groups/(add)?',
      exact: true,
    },
    component: UserGroupsListPage,
  },
  {
    name: 'Add User Group',
    requiredRoles: 'admin',
    link: { path: '/access/groups/add', exact: true },
    component: AddUserGroupPage,
  },
  {
    name: 'Enterprise SSO',
    requiredRoles: 'admin',
    link: {
      path: '/sso/:tab?/(edit||add)?/:id?',
      exact: true,
    },
    component: SsoManagementPage,
  },
  {
    name: 'Custom Theme',
    requiredRoles: 'admin',
    link: { path: '/theme', exact: true },
    component: CustomThemePage,
  },
  {
    name: 'API Access',
    link: { path: '/api-access', exact: true },
    component: ApiServicesPage,
  },
])

// These nav items are in active development but not shown in production.
export const navItems = [
  {
    name: 'User Settings',
    link: { path: '/settings/profile', definition: '/settings/:tab' },
    icon: 'users-cog',
  },
  {
    name: 'API Access',
    icon: 'key',
    link: { path: '/api-access', definition: '/api-access' },
  },
  {
    name: 'Tenants & Users',
    link: { path: '/access/users', definition: '/access/:tab' },
    icon: 'users',
    requiredRoles: 'admin',
    nestedLinks: [
      {
        name: 'Domains',
        link: { path: '/access/domains' },
        isHidden: () => !isSystemAdminUser(),
      },
      {
        name: 'Tenants',
        link: { path: '/access/tenants' },
      },
      {
        name: 'Users',
        link: { path: '/access/users' },
      },
      { name: 'Groups', link: { path: '/access/groups' } },
      { name: 'Roles', link: { path: '/access/roles' } },
    ],
  },
  {
    name: 'Enterprise SSO',
    link: { path: '/sso/configure', definition: '/sso/:tab' },
    icon: 'address-book',
    requiredRoles: 'admin',
    requiredFeatures: (features) => {
      // if (!features || !features.experimental) {
      //   return false
      // }
      // // Legacy DU & DDU have different conditions
      // if (features.experimental.kplane) {
      //   // Show user the upgrade dialog to FT users on the SSO screen itself
      //   return true
      // }
      // return features.experimental.sso
      return true
    },
  },
  {
    name: 'Custom Theme',
    link: { path: '/theme' },
    icon: 'palette',
    requiredRoles: 'admin',
  },
]

myAccountPlugin.registerNavItems(navItems)

export default myAccountPlugin

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { listUsers } from 'account/components/userManagement/users/new-actions'
import { usersSelector } from 'account/components/userManagement/users/selectors'
import { listTenants } from 'account/components/userManagement/tenants/new-actions'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import {
  updateMngmGroup,
  loadGroupRoleAssignments,
} from 'account/components/ssoManagement/groups/new-actions'
import { getGroupUsers } from './actions'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import Progress from 'core/components/progress/Progress'
import TenantRolesTableFieldDefault from '../users/TenantRolesTableField'
import Text from 'core/elements/Text'
import ListTableField from 'core/components/validatedForm/ListTableField'
import { pathStr } from 'utils/fp'
import DomainNameTextField from '../domains/DomainNameTextField'

const TenantRolesTableField: any = TenantRolesTableFieldDefault // types on forward ref .js file dont work well.

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  rows: any[]
  onClose: () => void
}

const columns = [{ id: 'username', label: 'Name' }]

export default function EditUserGroupModal({ rows: [group], onClose }: Props) {
  const classes = useStyles()
  const defaultParams = {
    name: '',
    description: '',
    roleAssignments: {},
    prevRoleAssignmentsArr: [],
    users: [],
    prevUserIds: [],
  }
  const [roleAssignments, setRoleAssignments] = useState(null)
  const [loadingRoleAssignments, setLoadingRoleAssignments] = useState(true)
  const [existingGroupUsers, setExistingGroupUsers] = useState(null)
  const [dataLoaded, setDataLoaded] = useState(false)
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenants = useSelector(tenantsSelector).filter(
    (tenant) => tenant.domain_id === group?.domain_id,
  )

  const { loading: loadingUsers } = useListAction(listUsers, {
    params: {
      domainId: group?.domain_id,
    },
  })
  const users = useSelector(usersSelector)

  const { update, updating, error, reset } = useUpdateAction(updateMngmGroup)

  const mappedUsers = useMemo(() => {
    if (!users?.length || !existingGroupUsers) {
      return []
    }
    const existingGroupUserIds = existingGroupUsers.map((user) => user.id)
    return users.map((user) => ({
      ...user,
      // ascending sort existing users to the top
      belongsToGroup: existingGroupUserIds.includes(user.id) ? 1 : 2,
    }))
  }, [users, existingGroupUsers])

  useEffect(() => {
    const makeCalls = async () => {
      const roles = await loadGroupRoleAssignments(group.id)
      setRoleAssignments(roles)
      const _users = await getGroupUsers(group.id)
      setExistingGroupUsers(_users)
      setLoadingRoleAssignments(false)
    }
    makeCalls()
  }, [])

  useEffect(() => {
    if (loadingRoleAssignments) {
      return
    }
    const existingGroupUserIds = existingGroupUsers.map((user) => user.id)
    const initialParams = {
      id: group.id,
      name: group.name,
      description: group.description,
      roleAssignments: roleAssignments?.reduce(
        (acc, roleAssignment) => ({
          ...acc,
          [pathStr('scope.project.id', roleAssignment)]: pathStr('role.id', roleAssignment),
        }),
        {},
      ),
      prevRoleAssignmentsArr: roleAssignments,
      users: mappedUsers.filter((user) => {
        return existingGroupUserIds.includes(user.id)
      }),
      prevUserIds: existingGroupUserIds,
    }
    updateParams(initialParams)
    setDataLoaded(true)
  }, [group, roleAssignments, loadingRoleAssignments])

  const initialContext = useMemo(() => {
    if (!existingGroupUsers) {
      return null
    }
    const existingGroupUserIds = existingGroupUsers.map((user) => user.id)
    return {
      id: group.id,
      name: group.name,
      description: group.description,
      roleAssignments: roleAssignments?.reduce(
        (acc, roleAssignment) => ({
          ...acc,
          [pathStr('scope.project.id', roleAssignment)]: pathStr('role.id', roleAssignment),
        }),
        {},
      ),
      prevRoleAssignmentsArr: roleAssignments,
      users: mappedUsers.filter((user) => {
        return existingGroupUserIds.includes(user.id)
      }),
      prevUserIds: existingGroupUserIds,
    }
  }, [group, roleAssignments, loadingRoleAssignments])

  const loadingSomething = loadingUsers || loadingTenants || loadingRoleAssignments || !dataLoaded

  const submitForm = useCallback(async () => {
    const body = {
      id: group.id,
      name: params.name,
      description: params.description,
      roleAssignments: params.roleAssignments,
      users: params.users,
      prevRoleAssignmentsArr: params.prevRoleAssignmentsArr,
      prevUserIds: params.prevUserIds,
    }
    const { success } = await update(body)
    if (success) {
      handleClose()
    }
  }, [group?.id, params])

  const handleClose = () => {
    setParams(defaultParams)
    setDataLoaded(false)
    setLoadingRoleAssignments(true)
    setRoleAssignments([])
    reset()
    onClose()
  }

  return (
    <ModalForm
      title={`Edit User Group`}
      onSubmit={submitForm}
      onClose={handleClose}
      loading={loadingSomething}
      submitting={updating}
      error={error}
      initialValues={initialContext}
      submitTitle={`Update User Group`}
      open
    >
      <>
        <FormFieldSection title="User Group Settings">
          <TextField
            id="name"
            label="Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            required
          />
          <TextField
            id="description"
            label="Description"
            onChange={getParamsUpdater('description')}
            value={params.description}
          />
          <DomainNameTextField domainId={group.domain_id} isReadOnly />
        </FormFieldSection>
        <FormFieldSection title="Tenants & Roles">
          <Text variant="body2">
            Specify what Tenants this Group should have access to, and what role the users will be
            assigned in each Tenant.
          </Text>
          <Progress
            renderContentOnMount={!loadingSomething}
            loading={loadingSomething}
            message={'Loading Tenants...'}
          >
            <TenantRolesTableField
              // validations={tenantRolesValidations}
              id="roleAssignments"
              tenants={tenants}
              onChange={getParamsUpdater('roleAssignments')}
              value={params.roleAssignments}
            />
          </Progress>
        </FormFieldSection>
        <FormFieldSection title="Users">
          <Text variant="body2">Specify which Users belong to this Group.</Text>
          <ListTableField
            id="users"
            data={users}
            loading={loadingUsers}
            columns={columns}
            onChange={getParamsUpdater('users')}
            value={params.users}
            uniqueIdentifier="id"
            searchTargets={['username']}
            multiSelection
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}

// import defaultTheme from 'core/themes/serenity/serenityDefault'
import lightTheme from 'core/themes/serenity/serenityLight'
import { ThemeConfig } from './model'
import { path } from 'ramda'

export const componentUpdaterObject = (themePath, value) => {
  return {
    pathTo: themePath,
    value: value || path(['components', ...themePath], lightTheme),
  }
}

export const generateThemeUpdatePayload = ({
  headerColor,
  sidenavColor,
  sidenavTextColor,
  sidenavActiveTextColor,
  sidenavBorderColor,
}: ThemeConfig) => {
  return {
    components: [
      // componentUpdaterObject(['header', 'background'], headerColor),
      componentUpdaterObject(['sidebar', 'background'], sidenavColor),
      componentUpdaterObject(['sidebar', 'text'], sidenavTextColor),
      componentUpdaterObject(['sidebar', 'activeText'], sidenavActiveTextColor),
      componentUpdaterObject(['sidebar', 'activeIcon'], sidenavActiveTextColor),
      componentUpdaterObject(['sidebar', 'hoverText'], sidenavActiveTextColor),
      componentUpdaterObject(['sidebar', 'border'], sidenavBorderColor),
    ],
  }
}

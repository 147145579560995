import ApiClient from 'api-client/ApiClient'
import { always, head, isNil, keys, pipe, prop, reject } from 'ramda'
import { tryCatchAsync } from 'utils/async'
import { emptyArr, pathStr } from 'utils/fp'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import store from 'app/store'
import moment from 'moment'
import { sessionActions, sessionStoreKey } from 'core/session/sessionReducers'
import { preferencesStoreKey } from 'core/session/preferencesReducers'
import { LoginMethodTypes } from './helpers'
import Bugsnag from 'utils/bugsnag'
import { trackEvent } from 'utils/tracking'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import CustomAction from 'core/actions/CustomAction'
import { listTenants } from 'account/components/userManagement/tenants/new-actions'
import getDataSelector from 'core/utils/getDataSelector'
import { cacheActions } from 'core/caching/cacheReducers'
import { ICredential } from './model'
import jwtDecode from 'jwt-decode'
import { isKaapiEnabled } from 'core/utils/helpers'
import config from 'app-config'
import { loginWithSsoUrl } from 'app/constants'
import { loadFeatures } from 'core/containers/AppController'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'

const { dispatch } = store

const { keystone, clemency, kubernetes } = ApiClient.getInstance()

const userRolesSelector = getDataSelector(DataKeys.ManagementUsersRoleAssignments, 'tenantId')

const authMethods = {
  [LoginMethodTypes.Local]: async (username, password, totp, domain) =>
    keystone.authenticate(username, password, totp, domain),
  [LoginMethodTypes.SSO]: async (_u, _p, _t) => keystone.authenticateSso(),
}

const reauthenticateUser = async ({ email, currentPassword, currentTenantId }) => {
  const userAuthInfo = await authenticateUser({
    loginUsername: email,
    password: currentPassword,
    loginMethod: LoginMethodTypes.Local,
    MFAcheckbox: false,
    mfa: '',
    reauthenticating: true,
  })

  await updateSession({
    ...userAuthInfo,
    currentTenantId,
    password: currentPassword,
    changeProjectScopeWithCredentials: true,
  })
}

export const authenticateUser = async ({
  loginUsername,
  password,
  loginMethod,
  MFAcheckbox,
  mfa,
  reauthenticating = false,
  domain = '',
}) => {
  const totp = MFAcheckbox ? mfa : ''
  const isSsoToken = loginMethod === LoginMethodTypes.SSO
  const state = store.getState()
  const { features } = state[sessionStoreKey]

  const { unscopedToken, username, expiresAt, issuedAt } = await authMethods[loginMethod](
    loginUsername,
    password,
    totp,
    domain,
  )

  if (unscopedToken) {
    const timeDiff = moment(expiresAt).diff(issuedAt)
    const localExpiresAt = moment()
      .add(timeDiff)
      .format()

    if (reauthenticating) {
      store.dispatch(
        sessionActions.updateSession({
          username,
          unscopedToken,
          expiresAt: localExpiresAt,
        }),
      )
    } else {
      store.dispatch(
        sessionActions.initSession({
          username,
          unscopedToken,
          expiresAt: localExpiresAt,
          features,
        }),
      )
    }
  }

  return { username, unscopedToken, expiresAt, issuedAt, isSsoToken }
}

const getRegion = async (currentRegion) => {
  if (currentRegion) {
    return currentRegion
  }

  const activeRegion = await keystone.getActiveRegion()
  if (activeRegion) {
    return activeRegion
  }

  const regions = await keystone.getRegions()
  return pipe(head, prop('id'))(regions)
}

export const updateSession = async ({
  username,
  unscopedToken,
  expiresAt,
  issuedAt,
  isSsoToken,
  currentTenantId,
  currentRegion = '',
  password = '',
  changeProjectScopeWithCredentials = false,
  domain = '',
  activeTenant = null,
}) => {
  const timeDiff = moment(expiresAt).diff(issuedAt)
  const localExpiresAt = moment()
    .add(timeDiff)
    .format()

  let user = null
  let scopedToken = null
  const isSSOLogin = isSsoToken || window.location.pathname.includes(loginWithSsoUrl)

  if (changeProjectScopeWithCredentials) {
    const response = await keystone.changeProjectScopeWithCredentials(
      username,
      password,
      currentTenantId,
    )
    user = response.user
    scopedToken = response.scopedToken
  } else {
    const response = await keystone.changeProjectScopeWithToken(currentTenantId, isSsoToken)
    user = response.user
    scopedToken = response.scopedToken
  }

  if (scopedToken) {
    // Kaapi Auth Setup
    await setupKappiSession({
      // For SSO Login password will be null - pass unscoped token as password and username as empty string
      username: !isSSOLogin && password ? username : '',
      password: !isSSOLogin && password ? password : unscopedToken,
      domain,
      activeTenant,
    })

    // Getting regions requires token to be set already
    const activeRegion = await getRegion(currentRegion)

    store.dispatch(
      sessionActions.updateSession({
        username,
        unscopedToken,
        scopedToken,
        activeRegion,
        expiresAt: localExpiresAt,
        userDetails: { ...user },
        isSsoToken,
        tokenData: {
          expiresAt,
          issuedAt,
        },
      }),
    )
    await keystone.resetCookie()
    await ApiClient.refreshApiEndpoints()

    return user
  }

  return null
}

export const credentialActions = ActionsSet.make<DataKeys.ManagementCredentials>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.ManagementCredentials,
  cacheKey: DataKeys.ManagementCredentials,
})

export const listCredentials = credentialActions.add(
  new ListAction<DataKeys.ManagementCredentials>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get credentials')
    return keystone.getCredentials()
  }),
)

export const createCredential = credentialActions.add(
  new CreateAction<DataKeys.ManagementCredentials, any>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to add new credentials')
    const result = await keystone.addCredential(params)
    trackEvent('Add New Credentials')
    return result
  }),
)

export const deleteCredential = credentialActions.add(
  new DeleteAction<DataKeys.ManagementCredentials, { id: string }>(async ({ id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete credential', { id })
    const result = await keystone.deleteCredential(id)
    trackEvent('Delete Credentials', { id })
    return result
  }),
)
export const userRoleAssignmentsActions = ActionsSet.make<DataKeys.ManagementUsersRoleAssignments>({
  uniqueIdentifier: ['user.id', 'scope.project.id', 'role.id'],
  indexBy: 'userId',
  entityName: entityNamesByKey.ManagementUsersRoleAssignments,
  cacheKey: DataKeys.ManagementUsersRoleAssignments,
  cache: false,
})

export const listUserRoleAssignments = userRoleAssignmentsActions.add(
  new ListAction<DataKeys.ManagementUsersRoleAssignments, { userId: string }>(
    async ({ userId }) => {
      Bugsnag.leaveBreadcrumb('Attempting to get user role assignments')
      return (await keystone.getUserRoleAssignments(userId)) || emptyArr
    },
  )
    .addDependency(DataKeys.ManagementCredentials)
    .addDependency(DataKeys.ManagementTenants),
)

export const getUserRoleAssignments = async ({ userId }) => {
  const result = await keystone.getUserRoleAssignments(userId)
  return result || emptyArr
}

export const userActions = ActionsSet.make<DataKeys.ManagementUsers>({
  uniqueIdentifier: ['id', 'domain_id'],
  entityName: entityNamesByKey.ManagementUsers,
  cacheKey: DataKeys.ManagementUsers,
})

export const listUsers = userActions.add(
  new ListAction<DataKeys.ManagementUsers, { domainId: string | undefined }>(
    async ({ domainId = undefined }) => {
      // Admin only API
      if (!isAdmin()) {
        return []
      }
      Bugsnag.leaveBreadcrumb('Attempting to get users')
      return await keystone.getUsers({ domainId })
    },
  ).addDependency(DataKeys.ManagementCredentials),
)

export const createUser = userActions.add(
  new CreateAction<
    DataKeys.ManagementUsers,
    {
      username: string
      displayname: string
      password: string
      roleAssignments: unknown[]
      activationType: string
      domain_id: string
    },
    any
  >(async ({ username, displayname, password, roleAssignments, activationType, domain_id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to create new user', { username, displayname })
    const defaultTenantId = pipe(keys, head)(roleAssignments)
    const createdUser =
      activationType === 'activationByEmail'
        ? await clemency.createUser({
            username,
            displayname,
            ui_version: 'serenity',
          })
        : await keystone.createUser({
            email: username,
            name: username,
            username,
            displayname,
            password: password || undefined,
            default_project_id: defaultTenantId,
            domain_id,
          })

    if (createdUser.role === 'member') {
      return createdUser
    }
    await tryCatchAsync(
      () =>
        Promise.all(
          Object.entries(roleAssignments).map(([tenantId, roleId]) =>
            keystone.addUserRole({ userId: createdUser.id, tenantId, roleId }),
          ),
        ),
      (err) => {
        console.warn((err || {}).message)
        return emptyArr
      },
    )(null)
    // We must invalidate the tenants cache so that they will contain the newly created user
    listTenants.call({})
    trackEvent('Create New User', { userId: createdUser.id })
    // Clemency call doesn't have displayname or is_local info, so add it here
    return {
      displayname,
      is_local: true,
      ...createdUser,
    }
  }),
)

export const updateUser = userActions.add(
  new UpdateAction<DataKeys.ManagementUsers, any, any>(
    async ({
      id: userId,
      username,
      displayname,
      password,
      enabled = true,
      roleAssignments,
      oldRoleAssignments,
      options,
    }) => {
      Bugsnag.leaveBreadcrumb('Attempting to update user', { userId })
      // Perform the api calls to update the user and the tenant/role assignments
      const updatedUser = await keystone.updateUser(userId, {
        username,
        name: username,
        email: username,
        displayname,
        password: password || undefined,
        enabled: enabled,
        options: options,
      })

      if (!roleAssignments) {
        return updatedUser
      }

      const prevRoleAssignmentsArr = oldRoleAssignments
        ? oldRoleAssignments
        : userRolesSelector(store.getState(), {
            userId,
          })

      const prevRoleAssignments = prevRoleAssignmentsArr.reduce(
        (acc, roleAssignment) => ({
          ...acc,
          [pathStr('scope.project.id', roleAssignment)]: pathStr('role.id', roleAssignment),
        }),
        {},
      )
      const mergedTenantIds = keys({ ...prevRoleAssignments, ...roleAssignments })

      const updateTenantRolesPromises = mergedTenantIds.map((tenantId) => {
        const prevRoleId = prevRoleAssignments[tenantId]
        const currRoleId = roleAssignments[tenantId]
        if (prevRoleId && !currRoleId) {
          // Remove unselected user/role pair
          return keystone
            .deleteUserRole({ userId, tenantId, roleId: prevRoleId })
            .then(always(null))
        } else if (!prevRoleId && currRoleId) {
          // Add new user and role
          return keystone.addUserRole({ userId, tenantId, roleId: currRoleId })
        } else if (prevRoleId && currRoleId && prevRoleId !== currRoleId) {
          // Update changed role (delete current and add new)
          return keystone
            .deleteUserRole({ userId, tenantId, roleId: prevRoleId })
            .then(() => keystone.addUserRole({ userId, tenantId, roleId: currRoleId }))
        }
        return Promise.resolve(null)
      }, [])

      // Resolve tenant and user/roles operation promises and filter out null responses
      await tryCatchAsync(
        () => Promise.all(updateTenantRolesPromises).then(reject(isNil)),
        (err) => {
          console.warn((err || {}).message)
          return emptyArr
        },
      )(null)

      listTenants.call({})
      // Refresh the user/roles cache
      listUserRoleAssignments.call({ userId })
      trackEvent('Update User', { userId })
      return updatedUser
    },
  ),
)

export const deleteUser = userActions.add(
  new DeleteAction<DataKeys.ManagementUsers, { id: string }>(async ({ id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete user', { userId: id })
    await keystone.deleteUser(id)
    // We must invalidate the tenants cache so that they will not contain the deleted user
    listTenants.call({})
    trackEvent('Delete User', { userId: id })
  }),
)

interface ICredentialParams {
  blob: string
  type: string
  user_id: string
}

interface IUserOptions {
  multi_factor_auth_rules: string[][]
}

export const enableMfa = userActions.add(
  new CustomAction<
    DataKeys.ManagementUsers,
    {
      credential: ICredentialParams
      userOptions: IUserOptions
      userId: string
    }
  >(
    'enableMFA',
    async ({ credential, userOptions, userId }) => {
      try {
        const cred = await createCredential.call(credential)
        // Need to resolve: User patch is not allowed for SSU
        const user = await updateUser.call({ id: userId, options: userOptions })
        return user
      } catch (err) {
        console.log('Error occurred. MFA not fully enabled.')
      }
    },
    (result, { userId }) => {
      // Update the user in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.ManagementUsers,
          params: { id: userId },
          item: result,
        }),
      )
    },
  ),
)

export const disableMfa = userActions.add(
  new CustomAction<
    DataKeys.ManagementUsers,
    { credential: ICredential; userOptions: IUserOptions; userId: string }
  >(
    'disableMFA',
    async ({ credential, userOptions, userId }) => {
      try {
        await deleteCredential.call({ id: credential.id })
        // Need to resolve: User patch is not allowed for SSU
        const user = await updateUser.call({ id: userId, options: userOptions })
        return user
      } catch (err) {
        console.log('Error occurred. MFA removal not fully successful.')
      }
    },
    (result, { userId }) => {
      // Update the user in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.ManagementUsers,
          params: { id: userId },
          item: result,
        }),
      )
    },
  ),
)

export const updateUserPassword = async ({ id, email, currentPassword, newPassword }) => {
  Bugsnag.leaveBreadcrumb('Attempting to update user password', { userId: id })
  try {
    const state = store.getState()
    const currentTenantId = state[preferencesStoreKey][email].root.currentTenant

    const params = {
      password: newPassword,
      original_password: currentPassword,
    }
    await keystone.updateUserPassword(id, params)
    trackEvent('Update User Password', { userId: id })
    // User must be authenticated again after a password change
    await reauthenticateUser({ email, currentPassword: newPassword, currentTenantId })
  } catch (err) {
    console.warn((err || {}).message)
    return false
  }
  return true
}

// DEX login for getting JWT token for k8s APIs
export const dexLogin = async ({ username, password, domain, activeTenant }): Promise<any> => {
  const tokenData = await kubernetes.peformDexLogin({
    username,
    password,
    domain: domain || 'default',
  })

  const jwtToken = tokenData?.id_token || config?.jwtToken
  // Update the session with the token
  if (jwtToken) {
    // Decode the JWT token to get the groups
    const jwtData: { groups: Array<string> } = jwtDecode(jwtToken)

    // Remove the last part of the group to get the tenant name
    const kaapiTenantList = Array.from(
      new Set(
        jwtData?.groups?.map((group) => {
          const groupParts = group.split('-')
          groupParts.pop()
          return groupParts.join('-')
        }),
      ),
    )

    // Update the session with the jwt token and tenant list
    store.dispatch(
      sessionActions.updateSession({
        jwtToken,
        kaapiTenantList,
        activeTenant: activeTenant?.name,
      }),
    )
    return { jwtToken, kaapiTenantList }
  }

  return { jwtToken: null, kaapiTenantList: [] }
}

export const setupKappiSession = async ({
  username,
  password,
  domain = 'default',
  activeTenant,
}) => {
  // Load clarity features just to make sure we have features loaded in session
  await loadFeatures()

  if (!isKaapiEnabled()) {
    return
  }

  // If Kaapi is enabled, login to Dex to get JWT token for Kaapi APIs
  const { jwtToken, kaapiTenantList } = await dexLogin({
    username,
    password,
    domain,
    activeTenant,
  })

  // If JWT token is not available, return
  if (!jwtToken) {
    return
  }

  updateKaapiTenant({ activeTenant, domain, kaapiTenantList })
}

// Checks for kaapi tenanat and create new tenant in kaapi if not available
export const updateKaapiTenant = ({ activeTenant, domain = 'default', kaapiTenantList }) => {
  // Find the tenant name in kaapi tenant list matching the active PCD tenant
  const activeKaapiTenant = kaapiTenantList?.find((kaapiTenant) =>
    kaapiTenant?.includes(`${domain}-${activeTenant?.name || activeTenant}`),
  )

  // Update the session with the active tenant
  store.dispatch(
    sessionActions.updateSession({
      activeTenant: activeTenant?.name || activeTenant,
      activeKaapiTenant,
    }),
  )
}

import React, { useCallback } from 'react'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import Modal from 'core/elements/modal'
import { updateDomain } from './actions'

export default function DeleteDomainModal({ rows: [domain], onClose }) {
  const { update: updateDomainFn, updating: updatingDomain, error } = useUpdateAction(updateDomain)

  const handleDelete = useCallback(async () => {
    const { success } = await updateDomainFn({
      id: domain?.id,
      body: { domain: { ...domain, enabled: !domain?.enabled } },
    })
    if (success) onClose(true)
  }, [domain])

  return (
    <Modal
      panel="dialog"
      title={domain?.enabled ? 'Disable Domain' : 'Enable Domain'}
      onClose={onClose}
      open
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={updatingDomain}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={updatingDomain}>
            Confirm
          </Button>
        </>
      }
    >
      <>
        <Text variant="body2">
          Are you sure you want to {domain?.enabled ? 'disable' : 'enable'}{' '}
          <strong>{domain?.name}</strong>?
        </Text>
        {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
      </>
    </Modal>
  )
}

import getDataSelector from 'core/utils/getDataSelector'
import DataKeys from 'k8s/DataKeys'
import { createSharedSelector } from 'core/utils/selectorHelpers'

export const domainsSelector = createSharedSelector(
  getDataSelector<DataKeys.Domains>(DataKeys.Domains),
  getDataSelector<DataKeys.ManagementTenants>(DataKeys.ManagementTenants),
  (domains, tenants) => {
    // Remove default domain forom list
    return domains.map((domain) => ({
      ...domain,
      tenants: tenants
        .reduce((acc, { domain_id, name }) => {
          if (domain_id === domain.id) acc.push(name)
          return acc
        }, [] as string[])
        .join(', '),
    }))
  },
)

export const rawDomainsSelector = createSharedSelector(
  getDataSelector<DataKeys.Domains>(DataKeys.Domains),
  getDataSelector<DataKeys.UserTenants>(DataKeys.UserTenants),
  (domains, userTenants) => {
    return domains.map((domain) => ({
      ...domain,
      tenants: userTenants
        .reduce((acc, { domain_id, name }) => {
          if (domain_id === domain.id) acc.push(name)
          return acc
        }, [] as string[])
        .join(', '),
    }))
  },
)

import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from 'k8s/components/common/entity/info-card'
import Progress from 'core/components/progress/Progress'
import Card from 'core/elements/card'
import Grid from 'core/elements/grid'
import {
  getTenantRoleAssignments,
  getComputeQuotaUsage,
  getCinderQuotaUsage,
  getNetworkQuotaUsage,
} from './new-actions'
import { listUsers } from 'account/components/userManagement/users/new-actions'
import { usersSelector } from 'account/components/userManagement/users/selectors'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listMngmRoles } from 'account/components/userManagement/roles/new-actions'
import { managementRolesSelector } from 'account/components/userManagement/roles/selectors'
import ProgressBar from 'core/components/progress/ProgressBar'
import Text from 'core/elements/Text'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  quotas: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content max-content',
    gap: 16,
    alignItems: 'center',
    padding: '16px 32px',
  },
}))

const metadataFields = [
  {
    id: 'name',
    title: 'Name',
  },
  {
    id: 'id',
    title: 'ID',
  },
  {
    id: 'description',
    title: 'Description',
  },
]

const userColumns = [
  {
    key: 'username',
    label: 'Username',
  },
  {
    key: 'roles',
    label: 'Role',
    formatFn: (value) => value?.join(', '),
  },
]

const QuotaGraph = ({ quotas, property, label }) => {
  const usedProperty = quotas?.[property]?.in_use !== undefined ? 'in_use' : 'used'
  const used = quotas?.[property]?.[usedProperty]
  const max = quotas?.[property]?.limit
  const percent = (used / max) * 100
  const unlimitedQuota = max === -1

  return (
    <>
      <Text variant="caption1">{label}:</Text>
      <Text variant="body2">
        {used} / {unlimitedQuota ? 'unlimited' : max}
      </Text>
      {!unlimitedQuota ? (
        <ProgressBar variant="health" percent={percent} showPercent={true} />
      ) : (
        <Text>{''}</Text> //This is needed otherwise it will break the grid ui
      )}
    </>
  )
}

export default function Overview({ tenant, loading }) {
  const classes = useStyles({})

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, tenant)
  }, [tenant])

  const [roleAssignments, setRoleAssignments] = useState([])
  const [loadingData, setLoadingData] = useState({
    users: true,
    compute: true,
    cinder: true,
    network: true,
  })
  const [computeQuotaUsage, setComputeQuotaUsage] = useState<any>({})
  const [cinderQuotaUsage, setCinderQuotaUsage] = useState<any>({})
  const [networkQuotaUsage, setNetworkQuotaUsage] = useState<any>({})

  const { loading: loadingUsers, reload: reloadUsers } = useListAction(listUsers, {
    params: {
      domainId: tenant?.domain_id || '',
    },
  })
  const users = useSelector(usersSelector)
  const { loading: loadingRoles } = useListAction(listMngmRoles)
  const roles = useSelector(managementRolesSelector)

  useEffect(() => {
    const makeCalls = async () => {
      const response = await getTenantRoleAssignments({ tenantId: tenant?.id })
      setRoleAssignments(response)
      setLoadingData((oldState) => ({ ...oldState, users: false }))
    }
    reloadUsers(true)
    makeCalls()
  }, [])

  useEffect(() => {
    const queryComputeQuota = async () => {
      const computeQuota = await getComputeQuotaUsage({ tenantId: tenant?.id })
      setComputeQuotaUsage({
        cores: computeQuota?.cores,
        ram: computeQuota?.ram,
        instances: computeQuota?.instances,
      })
      setLoadingData((oldState) => ({ ...oldState, compute: false }))
    }
    queryComputeQuota()
  }, [tenant?.id])

  useEffect(() => {
    const queryCinderQuota = async () => {
      const cinderQuota = await getCinderQuotaUsage({ tenantId: tenant?.id })
      setCinderQuotaUsage({
        blockStorage: cinderQuota?.gigabytes,
        volumes: cinderQuota?.volumes,
        volumeSnapshots: cinderQuota?.snapshots,
        maxVolumeSize: cinderQuota?.per_volume_gigabytes,
      })
      setLoadingData((oldState) => ({ ...oldState, cinder: false }))
    }
    queryCinderQuota()
  }, [tenant?.id])

  useEffect(() => {
    const queryNetworkQuota = async () => {
      const networkQuota = await getNetworkQuotaUsage({ tenantId: tenant?.id })
      setNetworkQuotaUsage({
        networks: networkQuota?.network,
        subnets: networkQuota?.subnet,
        routers: networkQuota?.router,
        ports: networkQuota?.port,
        floatingIps: networkQuota?.floatingip,
        securityGroups: networkQuota?.security_group,
        securityGroupRules: networkQuota?.security_group_rule,
      })
      setLoadingData((oldState) => ({ ...oldState, network: false }))
    }
    queryNetworkQuota()
  }, [])

  const usersWithRoles = useMemo(() => {
    if (users?.length && roles?.length && !loadingData?.users) {
      const roleDisplayMap = roles?.reduce((accum, role) => {
        return {
          ...accum,
          [role?.id]: role?.displayName,
        }
      }, {})
      const rolesByUserId = roleAssignments.reduce((accum, roleAssignment) => {
        if (accum[roleAssignment?.user?.id]) {
          return {
            ...accum,
            [roleAssignment?.user?.id]: [
              ...accum[roleAssignment?.user?.id],
              roleDisplayMap[roleAssignment?.role?.id],
            ],
          }
        } else {
          return {
            ...accum,
            [roleAssignment?.user?.id]: [roleDisplayMap[roleAssignment?.role?.id]],
          }
        }
      }, {})
      const usersWithRole = Object.keys(rolesByUserId)
      return users
        .filter((user) => {
          return usersWithRole?.includes(user?.id)
        })
        .map((user) => {
          return {
            ...user,
            roles: rolesByUserId[user?.id],
          }
        })
    }
    return []
  }, [users, roles, roleAssignments, loadingData.users])

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <InfoCard items={metadata} title="Properties" />
          <Card title="Users" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={usersWithRoles}
              columns={userColumns}
              loading={loadingData.users || loadingUsers || loadingRoles}
              compact
              disableToolbar
            />
          </Card>
        </div>
        <div className={classes.column}>
          <Card title="Compute Quota Usage" withCustomBody>
            <Progress loading={loadingData.compute}>
              <div className={classes.quotas}>
                <QuotaGraph quotas={computeQuotaUsage} property="cores" label="Cores" />
                <QuotaGraph quotas={computeQuotaUsage} property="ram" label="RAM (MiB)" />
                <QuotaGraph quotas={computeQuotaUsage} property="instances" label="VMs" />
              </div>
            </Progress>
          </Card>
          <Card title="Block Storage Quota Usage" withCustomBody>
            <Progress loading={loadingData.cinder}>
              <div className={classes.quotas}>
                <QuotaGraph
                  quotas={cinderQuotaUsage}
                  property="blockStorage"
                  label="Total Block Storage (GB)"
                />
                <QuotaGraph quotas={cinderQuotaUsage} property="volumes" label="Volumes" />
                <QuotaGraph
                  quotas={cinderQuotaUsage}
                  property="volumeSnapshots"
                  label="Volume Snapshots"
                />
                <QuotaGraph
                  quotas={cinderQuotaUsage}
                  property="maxVolumeSize"
                  label="Max Volume Size (GB)"
                />
              </div>
            </Progress>
          </Card>
          <Card title="Network Quota Usage" withCustomBody>
            <Progress loading={loadingData.network}>
              <div className={classes.quotas}>
                <QuotaGraph quotas={networkQuotaUsage} property="networks" label="Networks" />
                <QuotaGraph quotas={networkQuotaUsage} property="subnets" label="Subnets" />
                <QuotaGraph quotas={networkQuotaUsage} property="routers" label="Routers" />
                <QuotaGraph quotas={networkQuotaUsage} property="ports" label="Ports" />
                <QuotaGraph quotas={networkQuotaUsage} property="floatingIps" label="Public IPs" />
                <QuotaGraph
                  quotas={networkQuotaUsage}
                  property="securityGroups"
                  label="Security Groups"
                />
                <QuotaGraph
                  quotas={networkQuotaUsage}
                  property="securityGroupRules"
                  label="Security Group Rules"
                />
              </div>
            </Progress>
          </Card>
        </div>
      </div>
    </Progress>
  )
}

import React, { useState, useEffect } from 'react'
import { pick } from 'ramda'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { domainsSelector } from './selectors'
import { listDomains } from './actions'
import { GridViewColumn } from 'core/elements/grid/Grid'
import ListContainer from 'core/containers/ListContainer'
import CreateDomainModal from './CreateDomainModal'
import EditDomainModal from './EditDomainModal'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import { Domain } from './model'
import DeleteDomainModal from './DeleteDomainModal'
import EnableDisableDomainModal from './EnableDisableDomainModal'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import { isRestrictedDomains } from './helpers'

type ModelDataKey = DataKeys.Domains
type SelectorModel = Domain

const usePrefParams = createUsePrefParamsHook<TablePrefsParams>('Domains', listTablePrefs)

const searchTargets = ['name']

const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'id', label: 'Domain ID', display: false },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'enabled',
    label: 'Status',
    CellComponent: ({ value }) => <>{value ? 'Enabled' : 'Disabled'}</>,
  },
  { key: 'tenants', label: 'Tenants' },

  { key: 'description', label: 'Description' },
]

export default function DomainsListPage() {
  const [selectedDomain, setSelectedDomain] = useState<SelectorModel>(null)
  const [showEditDomainModal, setShowEditDomainModal] = useState<boolean>(false)
  const [showDeleteDomainModal, setShowDeleteDomainModal] = useState<boolean>(false)
  const [showDisableDomainModal, setShowDisableDomainModal] = useState<boolean>(false)

  const { params, getParamsUpdater } = usePrefParams({})
  const { message, loading, reload } = useListAction(listDomains)
  const data = useSelectorWithParams(domainsSelector, params)

  useEffect(() => {
    reload(true)
  }, [])

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      label: 'Edit',
      icon: 'edit',
      cond: (domain) => domain.every((d) => !isRestrictedDomains(d)),
      handleAction: ([domain]) => {
        setSelectedDomain(domain)
        setShowEditDomainModal(true)
      },
      refreshAfterSuccess: true,
      disabledTooltip: 'Not allowed for default and heat domains',
    },
    {
      label: 'Delete',
      icon: 'trash',
      cond: (domain) => domain.every((d) => !isRestrictedDomains(d)),
      handleAction: ([domain]) => {
        setSelectedDomain(domain)
        setShowDeleteDomainModal(true)
      },
      refreshAfterSuccess: true,
      disabledTooltip: 'Not allowed for default and heat domains',
    },
    {
      label: 'Enable',
      cond: (domain) => domain.every((d) => !isRestrictedDomains(d) && !d.enabled),
      icon: 'check-circle',
      handleAction: ([domain]) => {
        setSelectedDomain(domain)
        setShowDisableDomainModal(true)
      },
      refreshAfterSuccess: true,
      disabledTooltip: 'Not allowed for default and heat domains',
    },
    {
      label: 'Disable',
      cond: (domain) => domain.every((d) => !isRestrictedDomains(d) && d.enabled),
      icon: 'times-circle',
      handleAction: ([domain]) => {
        setSelectedDomain(domain)
        setShowDisableDomainModal(true)
      },
      refreshAfterSuccess: true,
      disabledTooltip: 'Not allowed for default and heat domains',
    },
  ]

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = batchActions.map((action) => ({
    label: action.label,
    icon: action.icon,
    handleClick: (selectedDomain) => action.handleAction([selectedDomain]),
    cond: (item) => action.cond([item]),
    refreshAfterSuccess: action.refreshAfterSuccess,
  }))

  return (
    <>
      <DocumentMeta title="Domains" />
      <CreateDomainModal addRoute={routes.userManagement.addDomain} />
      {showDisableDomainModal && (
        <EnableDisableDomainModal
          onClose={() => setShowDisableDomainModal(false)}
          rows={[selectedDomain]}
        />
      )}
      {showEditDomainModal && (
        <EditDomainModal onClose={() => setShowEditDomainModal(false)} rows={[selectedDomain]} />
      )}
      {showDeleteDomainModal && (
        <DeleteDomainModal
          onClose={() => setShowDeleteDomainModal(false)}
          rows={[selectedDomain]}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.Domains}
        searchTargets={searchTargets}
        nameProp="name"
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addUrl={routes.userManagement.addDomain.path()}
        addText="Create a New Domain"
        getParamsUpdater={getParamsUpdater}
        batchActions={batchActions}
        rowMenuItems={rowMenuItems}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}

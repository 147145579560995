import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listDomains } from './actions'
import { rawDomainsSelector } from './selectors'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function DomainPickList({
  name = 'domains',
  label = 'Domain',
  value = 'default',
  disabled = false,
  ...rest
}) {
  const { loading } = useListAction(listDomains)
  const domains = useSelector(rawDomainsSelector)
  const options = useMemo(() => {
    return domains.map((d) => ({
      label: d.name || d.id,
      value: d.id,
    }))
  }, [domains])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      loading={loading}
      items={options}
      value={value}
      disabled={disabled}
    />
  )
}

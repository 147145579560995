import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import TenantRolesTableFieldDefault from '../../userManagement/users/TenantRolesTableField'
import { requiredValidator } from 'core/utils/fieldValidators'
import { listTenants } from '../../userManagement/tenants/new-actions'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import Progress from 'core/components/progress/Progress'
import {
  groupFormSubmission,
  loadGroupRoleAssignments,
  listMngmGroups,
  listMngmGroupMappings,
} from './new-actions'
import GroupSettingsFields from './GroupSettingsFields'
import GroupCustomMappingsFields from './GroupCustomMappingsFields'
import { propEq } from 'ramda'
import { emptyObj, noop, pathStr } from 'utils/fp'
import uuid from 'uuid'
import { Criteria } from './CriteriaPicklist'
import GroupsTips from './GroupsTips'
import ModalForm from 'core/elements/modal/ModalForm'
import Column from 'core/containers/Column'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { groupsSelector, groupMappingsSelector } from './selectors'
import { Group } from './model'
import { defaultGroupMappingId } from 'app/constants'
import { getIDPName } from 'api-client/helpers'

const TenantRolesTableField: any = TenantRolesTableFieldDefault // types on forward ref .js file dont work well.

const useStyles = makeStyles((theme: Theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

const tenantRolesValidations = [requiredValidator.withMessage('Must select at least one tenant')]

const getMappingCriteria = (mapping) =>
  [Criteria.Contains, Criteria.DoesNotContain].find((type) => mapping.hasOwnProperty(type)) || ''

const EditGroupPage = () => {
  const { match, history } = useReactRouter()
  const classes = useStyles({})
  const groupId = match.params.id

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenants = useSelector(tenantsSelector)

  const { loading: loadingGroups } = useListAction(listMngmGroups)
  const groups = useSelector(groupsSelector)

  const { loading: loadingGroupMappings } = useListAction(listMngmGroupMappings)
  const groupMappings = useSelector(groupMappingsSelector)

  const group = useMemo(() => groups.find(propEq('id', groupId)) || (emptyObj as Group), [
    groups,
    groupId,
  ])
  const [roleAssignments, setRoleAssignments] = useState(null)
  const [loadingRoleAssignments, setLoadingRoleAssignments] = useState(true)
  const [submitting, updateSubmitting] = useState(false)
  const existingMapping = groupMappings.find((m) => m.id === defaultGroupMappingId(getIDPName()))

  const mappingRule = useMemo(() => {
    if (!existingMapping) {
      return null
    }
    return existingMapping?.rules?.find(
      (rule) =>
        group.id === rule.local?.[0]?.group?.id || group.name === rule.local?.[0]?.group?.name,
    )
    // Needed to add groupId as a dependency bc the modal loads in the background on list page
  }, [groupMappings, group, existingMapping])

  const initialContext = useMemo(
    () => ({
      groupId: groupId,
      name: group.name,
      description: group.description,
      firstNameKey: mappingRule?.remote?.[0]?.type,
      lastNameKey: mappingRule?.remote?.[1]?.type,
      emailKey: mappingRule?.remote?.[2]?.type,
      customMappings:
        mappingRule?.remote?.slice(3).map((mapping) => ({
          id: uuid.v4(),
          attribute: mapping.type,
          criteria: getMappingCriteria(mapping),
          values: mapping[getMappingCriteria(mapping)]?.join(', ') || '',
          regexMatching: mapping.regex,
        })) || [],
      roleAssignments: roleAssignments?.reduce(
        (acc, roleAssignment) => ({
          ...acc,
          [pathStr('scope.project.id', roleAssignment)]: pathStr('role.id', roleAssignment),
        }),
        {},
      ),
    }),
    [group, mappingRule, roleAssignments],
  )

  useEffect(() => {
    const loadRoles = async () => {
      setLoadingRoleAssignments(true)
      const roles = await loadGroupRoleAssignments(groupId)
      setRoleAssignments(roles)
      setLoadingRoleAssignments(false)
    }

    loadRoles()
  }, [groupId])

  const loadingSomething =
    loadingGroups || loadingGroupMappings || !roleAssignments || loadingRoleAssignments

  const submitForm = useCallback(
    async (params) => {
      updateSubmitting(true)
      await groupFormSubmission({
        params: {
          ...params,
          // prevRoleAssignmentsArr & groupId not being passed through submit fn in ValidatedForm
          group,
          prevRoleAssignmentsArr: roleAssignments,
        },
        existingMapping,
        operation: 'update',
        protocolExists: true,
      })
      updateSubmitting(false)
      history.push(routes.sso.root.path({ tab: 'groups' }))
    },
    [group, roleAssignments, existingMapping],
  )

  return (
    <>
      <DocumentMeta title="Edit Group" bodyClasses={['form-view']} />
      <ModalForm
        route={routes.sso.editGroup}
        title="Edit Group"
        initialValues={initialContext}
        onBackdropClick={noop}
        onClose={() => history.push(routes.sso.root.path({ tab: 'groups' }))}
        onSubmit={submitForm}
        submitTitle="Edit Group"
        loading={loadingSomething}
        submitting={submitting}
        loadingMessage={submitting ? 'Submitting form...' : 'Loading Group...'}
      >
        <>
          {({ setFieldValue, values }) => {
            return (
              <>
                <Column gap={16}>
                  <GroupsTips />
                  <FormFieldSection title="Group Settings" step={1}>
                    <Column gap={16}>
                      <GroupSettingsFields
                        setFieldValue={setFieldValue}
                        values={values}
                        groups={groups}
                        groupId={groupId}
                      />
                    </Column>
                    <GroupCustomMappingsFields
                      id="customMappings"
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  </FormFieldSection>
                  <FormFieldSection title="Tenants & Roles" step={2}>
                    <Text variant="body2">
                      Specify what Tenants this Group should have access to, and what role the users
                      will be assigned in each Tenant.
                    </Text>
                    <Progress
                      renderContentOnMount={!loadingTenants}
                      loading={loadingTenants}
                      message={'Loading Tenants...'}
                    >
                      <TenantRolesTableField
                        validations={tenantRolesValidations}
                        id="roleAssignments"
                        tenants={tenants}
                      />
                    </Progress>
                  </FormFieldSection>
                </Column>
              </>
            )
          }}
        </>
      </ModalForm>
    </>
  )
}

export default EditGroupPage

import React from 'react'
import useListAction from 'core/hooks/useListAction'
import { rawDomainsSelector } from './selectors'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { listDomains } from './actions'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Theme from 'core/themes/model'

interface Props {
  domainId: string
  isReadOnly?: boolean
}

const DomainNameTextField = ({ domainId, isReadOnly }: Props) => {
  const classes = useStyles()
  useListAction(listDomains)
  const domains = useSelector(rawDomainsSelector)
  const domain = useMemo(() => domains.find((d) => d.id === domainId), [domainId, domains])

  return (
    <TextField
      className={isReadOnly ? classes.readonly : undefined}
      id="domainName"
      label="Domain Name"
      readOnly={isReadOnly}
      value={domain?.name}
    />
  )
}

export default DomainNameTextField

const useStyles = makeStyles<Theme>((_theme) => ({
  readonly: {
    '& input': {
      cursor: 'not-allowed',
    },
  },
}))

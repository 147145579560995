import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import { routes } from 'core/utils/routes'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listTenants } from './new-actions'
import { tenantsSelector } from './selectors'
import { ITenantDetailsPageTabs } from './model'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Overview from './Overview'

const useStyles = makeStyles<Theme>((theme) => ({
  grid: {
    display: 'grid',
    gap: 32,
  },
  actions: {
    display: 'grid',
    gridAutoColumns: 'auto max-content',
    gridAutoFlow: 'column',
    gap: 16,
  },
}))

const TenantDetailsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id } = match.params
  const { loading } = useListAction(listTenants)
  const tenants = useSelectorWithParams(tenantsSelector, {})
  const tenant = useMemo(() => tenants.find((tenant) => tenant.id === id) || { name: '' }, [
    id,
    tenants,
  ])

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        id: tenant?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [tenant?.name, id])

  return (
    <>
      <DocumentMeta title="Tenant Details" breadcrumbs />
      <Tabs route={routes.userManagement.tenantDetails}>
        <Tab label="Overview" value={ITenantDetailsPageTabs.Overview}>
          <Overview tenant={tenant} loading={loading} />
        </Tab>
      </Tabs>
    </>
  )
}

export default TenantDetailsPage

import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import { defaultGroupMappingId } from 'app/constants'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { always, isNil, keys, reject } from 'ramda'
import { tryCatchAsync } from 'utils/async'
import { emptyArr, pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import { formMappingRule } from './helpers'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import store from 'app/store'
import { notificationActions, NotificationType } from 'core/notifications/notificationReducers'
import { getDomainId, getIDPName } from 'api-client/helpers'

const { keystone } = ApiClient.getInstance()
const { dispatch } = store

const getUserGroupOperations = (users, existingUserIds) => {
  const newUserIds = users.map((user) => user.id)
  return {
    add: newUserIds.filter((user) => !existingUserIds.includes(user)),
    remove: existingUserIds.filter((user) => !newUserIds.includes(user)),
  }
}

export const mngmGroupMappingActions = ActionsSet.make<DataKeys.ManagementGroupsMappings>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.ManagementGroupsMappings,
  cacheKey: DataKeys.ManagementGroupsMappings,
})

export const listMngmGroupMappings = mngmGroupMappingActions.add(
  new ListAction<DataKeys.ManagementGroupsMappings>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get SSO group mappings')
    return keystone.getGroupMappings()
  }),
)

export const createMngmGroupMapping = mngmGroupMappingActions.add(
  new CreateAction<DataKeys.ManagementGroupsMappings, any, any>(async ({ id, ...params }) => {
    Bugsnag.leaveBreadcrumb('Attempting to create SSO group mapping', { ...params })
    return keystone.createGroupMapping(id, params)
  }),
)

export const updateMngmGroupMapping = mngmGroupMappingActions.add(
  new UpdateAction<DataKeys.ManagementGroupsMappings, any, any>(async ({ id, ...params }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update SSO group mapping', { id, ...params })
    return keystone.updateGroupMapping(id, params)
  }),
)

export const deleteMngmGroupMapping = mngmGroupMappingActions.add(
  new DeleteAction<DataKeys.ManagementGroupsMappings, { id: string }>(async ({ id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete SSO group mapping', { id })
    return keystone.deleteGroupMapping(id)
  }),
)

export const mngmGroupActions = ActionsSet.make<DataKeys.ManagementGroups>({
  uniqueIdentifier: ['id', 'domain_id'],
  entityName: entityNamesByKey.ManagementGroups,
  cacheKey: DataKeys.ManagementGroups,
})

export const listMngmGroups = mngmGroupActions.add(
  new ListAction<DataKeys.ManagementGroups, { domainId: string | undefined }>(
    async ({ domainId = undefined }) => {
      Bugsnag.leaveBreadcrumb('Attempting to get SSO groups')
      return keystone.getGroups({ domainId })
    },
  ).addDependency(DataKeys.ManagementGroupsMappings),
)

export const createMngmGroup = mngmGroupActions.add(
  new CreateAction<DataKeys.ManagementGroups, any, any>(
    async ({ roleAssignments = {}, users, ...params }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create group', { ...params })
      const createdGroup = await keystone.createGroup(params)

      await tryCatchAsync(
        () =>
          Promise.all(
            Object.entries(roleAssignments).map(([tenantId, roleId]) =>
              keystone.addGroupRole({ groupId: createdGroup.id, tenantId, roleId }),
            ),
          ),
        (err) => {
          console.warn(err?.message)
          return emptyArr
        },
      )(null)

      await tryCatchAsync(
        () =>
          Promise.all(
            users.map((user) =>
              keystone.addGroupUser({ groupId: createdGroup.id, userId: user.id }),
            ),
          ),
        (err) => {
          console.warn(err?.message)
          return emptyArr
        },
      )(null)

      trackEvent('Create Group', { groupId: createdGroup.id })
      return createdGroup
    },
  ),
)

export const updateMngmGroup = mngmGroupActions.add(
  new UpdateAction<DataKeys.ManagementGroups, any, any>(
    async ({
      id: groupId,
      roleAssignments,
      prevRoleAssignmentsArr,
      users = [],
      prevUserIds = [],
      ...params
    }) => {
      Bugsnag.leaveBreadcrumb('Attempting to update group', { groupId, ...params })

      const prevRoleAssignments = prevRoleAssignmentsArr.reduce(
        (acc, roleAssignment) => ({
          ...acc,
          [pathStr('scope.project.id', roleAssignment)]: pathStr('role.id', roleAssignment),
        }),
        {},
      )
      const mergedTenantIds = keys({ ...prevRoleAssignments, ...roleAssignments })

      // Perform the api calls to update the user and the tenant/role assignments
      const updatedGroupPromise = keystone.updateGroup(groupId, params)
      const updateTenantRolesPromises = mergedTenantIds.map((tenantId) => {
        const prevRoleId = prevRoleAssignments[tenantId]
        const currRoleId = roleAssignments[tenantId]
        if (prevRoleId && !currRoleId) {
          // Remove unselected user/role pair
          return keystone
            .deleteGroupRole({ groupId, tenantId, roleId: prevRoleId })
            .then(always(null))
        } else if (!prevRoleId && currRoleId) {
          // Add new user and role
          return keystone.addGroupRole({ groupId, tenantId, roleId: currRoleId })
        } else if (prevRoleId && currRoleId && prevRoleId !== currRoleId) {
          // Update changed role (delete current and add new)
          return keystone
            .deleteGroupRole({ groupId, tenantId, roleId: prevRoleId })
            .then(() => keystone.addGroupRole({ groupId, tenantId, roleId: currRoleId }))
        }
        return Promise.resolve(null)
      }, [])

      const userOperations = getUserGroupOperations(users, prevUserIds)
      const addUserToGroupPromises = userOperations.add.map((userId) => {
        return keystone.addGroupUser({ groupId, userId })
      })
      const removeUserFromGroupPromises = userOperations.remove.map((userId) => {
        return keystone.removeGroupUser({ groupId, userId })
      })

      // Resolve tenant and user/roles operation promises and filter out null responses
      const [updatedGroup] = await Promise.all([
        updatedGroupPromise,
        tryCatchAsync(
          () => Promise.all(updateTenantRolesPromises).then(reject(isNil)),
          (err) => {
            console.warn(err?.message)
            return emptyArr
          },
        )(null),
        tryCatchAsync(
          () => Promise.all(addUserToGroupPromises).then(reject(isNil)),
          (err) => {
            console.warn(err?.message)
            return emptyArr
          },
        )(null),
        tryCatchAsync(
          () => Promise.all(removeUserFromGroupPromises).then(reject(isNil)),
          (err) => {
            console.warn(err?.message)
            return emptyArr
          },
        )(null),
      ])
      trackEvent('Update Group', { groupId })
      return updatedGroup
    },
  ),
)

export const deleteMngmGroup = mngmGroupActions.add(
  new DeleteAction<DataKeys.ManagementGroups, { id: string; name: string }>(
    async ({ id, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete SSO group', { id })
      const groupMappings = await listMngmGroupMappings.call({})
      const mapping = await (groupMappings as any[])?.find(
        (m) => m.id === defaultGroupMappingId(getIDPName()),
      )
      const updatedMappingRules = mapping?.rules?.filter(
        (rule) => !(id === rule.local?.[0]?.group?.id || name === rule.local?.[0]?.group?.name),
      )

      // If there are no rules left, delete the mapping as empty mappings are not allowed by API
      updatedMappingRules.length === 0
        ? await deleteMngmGroupMapping.call({ id: mapping.id })
        : await updateMngmGroupMapping.call({
            id: mapping.id,
            rules: updatedMappingRules,
          })

      const result = await keystone.deleteGroup(id)
      trackEvent('Delete Group', { groupId: id })
      return result
    },
  ),
)

export const loadGroupRoleAssignments = async (groupId) => keystone.getGroupRoleAssignments(groupId)

export const loadIdpProtocols = async () => keystone.getIdpProtocols()

// Not using below at the moment, this loads once and is stored in cache,
// and is never updated again. Setting cache to false or invalidating cache doesn't work
// export const mngmGroupRoleAssignmentsLoader = createContextLoader(
//   DataKeys.ManagementGroupsRoleAssignments,
//   async ({ groupId }) => {
//     const groupRoleAssignments = await keystone.getGroupRoleAssignments(groupId)
//     return groupRoleAssignments || emptyArr
//   },
//   {
//     uniqueIdentifier: ['group.id', 'role.id'],
//     indexBy: 'groupId',
//     cache: false,
//   },
// )

export const groupFormSubmission = async ({
  params,
  existingMapping,
  operation,
  protocolExists,
}) => {
  try {
    // Create/update the group and get the group id
    const groupBody = {
      name: params.name,
      description: params.description,
      domain_id: getDomainId(),
    }
    const groupOperationMap = {
      create: createMngmGroup,
      update: updateMngmGroup,
    }
    const groupOperation = groupOperationMap[operation]

    const group = await groupOperation.call({
      id: params?.group?.id,
      roleAssignments: params.roleAssignments,
      prevRoleAssignmentsArr: params.prevRoleAssignmentsArr,
      ...groupBody,
    })

    const groupId = group?.id

    // Add group to the mapping
    const ruleBody = formMappingRule(params, groupId)
    const mappingBody =
      operation === 'update'
        ? {
            rules: existingMapping.rules.map((rule) => {
              // Upate the rule if the group id or name matches
              if (
                rule.local?.[0]?.group?.id === params?.group?.id ||
                rule.local?.[0]?.group?.name === params?.group?.name
              ) {
                return ruleBody
              }
              // Else send the existing rule
              return rule
            }),
          }
        : existingMapping
        ? {
            rules: [...existingMapping.rules, ruleBody],
          }
        : {
            rules: [ruleBody],
          }

    // If there are no rules left, delete the mapping as empty mappings are not allowed by API
    existingMapping
      ? mappingBody.rules.length === 0
        ? await deleteMngmGroupMapping.call({ id: existingMapping.id })
        : await updateMngmGroupMapping.call({ id: existingMapping.id, ...mappingBody })
      : await createMngmGroupMapping.call({
          id: defaultGroupMappingId(getIDPName()),
          ...mappingBody,
        })

    // Link the IDP with the newly created mapping
    if (!protocolExists) {
      await keystone.addIdpProtocol(defaultGroupMappingId(getIDPName()))
    }
  } catch (err) {
    dispatch(
      notificationActions.registerNotification({
        title: `Error during SAML Group ${operation}`,
        message: err?.err?.message,
        data: err,
        type: NotificationType.error,
      }),
    )
  }
}

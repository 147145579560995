import React from 'react'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'
import { RootState } from 'app/store'
import { isAdminRole } from 'k8s/util/helpers'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import MyProfilePage from './my-profile-page'
import MFASettingsPage from './MFASettingsPage'

const UserSettingsIndexPage = () => {
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { isSsoToken } = session

  if (!isAdminRole(session)) {
    return (
      <>
        <DocumentMeta title="My Account" />
        <MyProfilePage />
      </>
    )
  }
  return (
    <>
      <DocumentMeta title="My Account" breadcrumbs />
      <Tabs route={routes.userSettings.root}>
        <Tab value="profile" label="Account Details">
          <MyProfilePage />
        </Tab>
        {!isSsoToken && (
          <Tab value="mfa" label="Multifactor Authentication">
            <MFASettingsPage />
          </Tab>
        )}
      </Tabs>
    </>
  )
}

export default UserSettingsIndexPage

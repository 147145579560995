import React, { useCallback } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createDomain, listDomains } from './actions'
import { Route } from 'core/plugins/route'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import useListAction from 'core/hooks/useListAction'
import { CreateDomain } from 'api-client/keystone.model'
import ModalForm from 'core/elements/modal/ModalForm'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 21fr',
    gap: 16,
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  toggleSwitch: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}))

interface Props {
  addRoute: Route
}

export default function CreateDomainModal({ addRoute }: Props) {
  const classes = useStyles()
  const { history } = useReactRouter()
  const defaultParams = {
    name: '',
    description: '',
    enabled: true,
  }
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)
  const { reload: reloadDomains } = useListAction(listDomains)
  const { update, updating, error, reset } = useUpdateAction(createDomain)

  const handleClose = useCallback(() => {
    setParams(defaultParams)
    reset()
    history.push(routes.userManagement.domains.path())
  }, [setParams, reset, history])

  const submitForm = useCallback(async () => {
    const body: CreateDomain = {
      domain: { name: params.name, description: params.description, enabled: params.enabled },
    }
    const { success } = await update(body)
    if (success) {
      reloadDomains(true, true)
      handleClose()
    }
  }, [params, update, reloadDomains, handleClose])

  return (
    <ModalForm
      route={addRoute}
      title={`Create Domain`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      initialValues={defaultParams}
      submitTitle={`Create Domain`}
    >
      <>
        <div className={classes.header}>
          <FontAwesomeIcon>globe</FontAwesomeIcon>
          <Text variant="body2">
            Domains enable the creation of multiple sub-organizations within a main organization,
            logically grouping isolated infrastructure and users. Each domain exclusively owns one
            or more tenants, which cannot be shared across domains.
          </Text>
        </div>
        <FormFieldSection title="Domain Configuration">
          <TextField
            id="name"
            label="Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            required
          />
          <TextField
            id="description"
            label="Description"
            onChange={getParamsUpdater('description')}
            value={params.description}
          />
          <div className={classes.toggleSwitch}>
            <ToggleSwitchField
              id="enabled"
              label="Enable Domain"
              value={params.enabled}
              onChange={(value) => getParamsUpdater('enabled')(value)}
              tooltip={
                <>
                  Enabling the domain will make it available for users to assign roles to users and
                  groups.
                </>
              }
            />
          </div>
        </FormFieldSection>
      </>
    </ModalForm>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listTenants, listUserTenants } from 'account/components/userManagement/tenants/new-actions'
import {
  getUserRoleAssignments,
  updateUser,
} from 'account/components/userManagement/users/new-actions'
import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { pathStr } from 'utils/fp'
import TenantRolesTableField from 'account/components/userManagement/users/TenantRolesTableField'
import useListAction from 'core/hooks/useListAction'
import { useDispatch, useSelector } from 'react-redux'
import Progress from 'core/components/progress/Progress'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import useToggler from 'core/hooks/useToggler'
import SimpleLink from 'core/components/SimpleLink'
import Input from 'core/elements/input/Input'
import clsx from 'clsx'
import UserPasswordField from 'account/components/userManagement/users/UserPasswordField'
import { prop } from 'ramda'
import { SessionState, sessionActions, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { setActiveRegion } from 'api-client/helpers'
import { requiredValidator } from 'core/utils/fieldValidators'
import DomainNameTextField from '../domains/DomainNameTextField'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  rows: any[]
  onClose: () => void
}

const tenantRolesValidations = [requiredValidator.withMessage('Must select at least one tenant')]

const TogglableTextField = ({
  id,
  label,
  initialValue,
  value,
  onChange,
  required = false,
  TextFieldComponent = TextField,
}) => {
  const classes = useStyles()
  const [showingField, toggleField] = useToggler()
  return (
    <div className={clsx('togglableFieldContainer', classes.togglableFieldContainer)}>
      {showingField ? (
        <TextFieldComponent
          onChange={onChange}
          id={id}
          label={label}
          value={value}
          required={required}
        />
      ) : (
        <Input className={classes.togglableField} label={label} value={initialValue} disabled />
      )}
      <SimpleLink className={classes.togglableFieldBtn} onClick={toggleField} label={label}>
        {showingField ? 'Cancel' : 'Change'}
      </SimpleLink>
    </div>
  )
}

export default function EditUserModal({ rows: [user], onClose }: Props) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const defaultParams = {
    username: '',
    displayname: '',
    password: '',
    roleAssignments: {},
  }
  const [roleAssignments, setRoleAssignments] = useState([])
  const [loadingRoleAssignments, setLoadingRoleAssignments] = useState(true)
  const [dataLoaded, setDataLoaded] = useState(false)
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenants = useSelector(tenantsSelector).filter(
    (tenant) => tenant.domain_id === user?.domain_id,
  )

  const { update, updating, error, reset } = useUpdateAction(updateUser)
  const { reload: reloadUserTenants } = useListAction(listUserTenants)

  useEffect(() => {
    const makeCalls = async () => {
      const response = await getUserRoleAssignments({ userId: user.id })
      setRoleAssignments(response)
      setLoadingRoleAssignments(false)
    }
    makeCalls()
  }, [])

  useEffect(() => {
    if (loadingRoleAssignments) {
      return
    }
    const initialParams = {
      id: user.id,
      username: user.username,
      displayname: user.displayname,
      roleAssignments: roleAssignments.reduce(
        (acc, roleAssignment) => ({
          ...acc,
          [pathStr('scope.project.id', roleAssignment)]: pathStr('role.id', roleAssignment),
        }),
        {},
      ),
    }
    updateParams(initialParams)
    setDataLoaded(true)
  }, [user, roleAssignments, loadingRoleAssignments])

  const initialContext = useMemo(() => {
    return {
      id: user.id,
      name: user.name,
      description: user.description || '',
      roleAssignments: roleAssignments.reduce(
        (acc, roleAssignment) => ({
          ...acc,
          [pathStr('scope.project.id', roleAssignment)]: pathStr('role.id', roleAssignment),
        }),
        {},
      ),
    }
  }, [user, roleAssignments, loadingRoleAssignments])

  const userId = user.id
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { userDetails } = session
  const { id: activeUserId, email: activeUserEmail } = userDetails
  const isActiveUser = userId === activeUserId
  const showPasswordField = !isActiveUser

  const { prefs, updatePrefs } = useScopedPreferences()
  const [oldUserPrefs, setOldUserPrefs] = useState(prefs)
  const [activeUserUpdated, setActiveUserUpdated] = useState(false)

  const loadingSomething = loadingTenants || loadingRoleAssignments || !dataLoaded

  useEffect(() => {
    // When the active user's email gets updated, transfer their old prefs over to the new email
    if (activeUserUpdated) {
      updatePrefs(oldUserPrefs)
      if (oldUserPrefs.currentRegion) {
        setActiveRegion(oldUserPrefs.currentRegion)
      }
      handleClose()
    }
  }, [activeUserEmail])

  const submitForm = useCallback(async () => {
    const body = {
      id: user?.id,
      username: params.username,
      displayname: params.displayname,
      password: params.password,
      roleAssignments: params.roleAssignments,
      oldRoleAssignments: roleAssignments,
    }
    const { success, response: updatedUser } = await update(body)
    if (!success) return
    if (isActiveUser) {
      setActiveUserUpdated(true)
      dispatch(
        sessionActions.updateSession({
          username: updatedUser.email,
          userDetails: {
            ...userDetails,
            username: updatedUser.email,
            name: updatedUser.email,
            email: updatedUser.email,
            displayName: updatedUser.displayname, // displayName is a UI variable
            displayname: updatedUser.displayname, // displayname is what we get from the api
          },
        }),
      )
      reloadUserTenants(true, true)
      handleClose()
    } else {
      handleClose()
    }
  }, [user?.id, params, roleAssignments])

  const handleClose = () => {
    setParams(defaultParams)
    setDataLoaded(false)
    setLoadingRoleAssignments(true)
    setRoleAssignments([])
    reset()
    onClose()
  }

  return (
    <ModalForm
      title={`Edit User`}
      onSubmit={submitForm}
      onClose={handleClose}
      loading={loadingSomething}
      submitting={updating}
      error={error}
      initialValues={initialContext}
      submitTitle={`Update User`}
      open
    >
      <>
        <FormFieldSection title="User Settings">
          <TogglableTextField
            id="username"
            label="Username or Email"
            initialValue={user.username}
            onChange={getParamsUpdater('username')}
            value={params.username}
            required
          />
          <TogglableTextField
            id="displayname"
            label="Display Name"
            initialValue={user.displayname}
            onChange={getParamsUpdater('displayname')}
            value={params.displayname}
          />
          {showPasswordField && (
            <TogglableTextField
              id="password"
              label="Password"
              initialValue={'********'}
              value={params.password}
              // @ts-ignore
              TextFieldComponent={UserPasswordField}
              onChange={getParamsUpdater('password')}
            />
          )}
          <DomainNameTextField domainId={user?.domain_id} isReadOnly />
        </FormFieldSection>
        <FormFieldSection title="Tenant Assignment">
          <Progress
            renderContentOnMount={!loadingSomething}
            loading={loadingSomething}
            message={'Loading Tenants...'}
          >
            <TenantRolesTableField
              validations={tenantRolesValidations}
              id="roleAssignments"
              tenants={tenants}
              onChange={getParamsUpdater('roleAssignments')}
              value={params.roleAssignments}
            />
          </Progress>
        </FormFieldSection>
      </>
    </ModalForm>
  )
}

import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import clsx from 'clsx'
import TextField from 'core/components/validatedForm/TextField'
import { colorHexValidator, customValidator } from 'core/utils/fieldValidators'
import { hexToRgb, rgbToHex } from 'core/utils/colorHelpers'

interface StyleProps {
  defaultHex: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  colorDisplay: {
    display: 'inline-block',
    height: 48,
    width: '100%',
    border: `1px solid ${theme.palette.grey[200]}`,
    marginTop: 8,
    borderRadius: 4,
  },
  backgroundColor: {
    background: ({ defaultHex }) => defaultHex,
  },
  rgb: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: theme.spacing(1),
  },
  rgbTextField: {
    width: 'initial !important',
    '& .MuiOutlinedInput-root': {
      minWidth: 'initial',
    },
  },
}))

export default function ColorPicker({
  params,
  getParamsUpdater,
  updateParams,
  property,
  label,
  defaultHex = '#f5f5f9',
}) {
  const classes = useStyles({ defaultHex })
  const [r, setR] = useState('')
  const [g, setG] = useState('')
  const [b, setB] = useState('')

  useEffect(() => {
    if (!property || !params[property]) {
      return
    }
    const rgb = hexToRgb(params[property])
    if (rgb) {
      setR(`${rgb.r}`)
      setG(`${rgb.g}`)
      setB(`${rgb.b}`)
    }
  }, [params, property])

  useEffect(() => {
    if (r && g && b) {
      updateParams({
        [property]: rgbToHex(r, g, b),
      })
    }
  }, [r, g, b])

  return (
    <div>
      <Text variant="caption1" component="div">
        {label}
      </Text>
      <div
        className={clsx(classes.colorDisplay, classes.backgroundColor)}
        style={{
          background: params[property]?.length === 7 && params[property],
        }}
      />
      <TextField
        id={property}
        label="Hex Value"
        onChange={getParamsUpdater(property)}
        value={params[property]}
        placeholder={defaultHex}
        maxLength={7}
        validations={[colorHexValidator]}
      />
      <div className={classes.rgb}>
        <TextField
          id="backgroundR"
          label="R"
          onChange={(val) => setR(val)}
          value={r}
          placeholder="13"
          className={classes.rgbTextField}
          type="number"
          min={0}
          max={255}
        />
        <TextField
          id="backgroundG"
          label="G"
          onChange={(val) => setG(val)}
          value={g}
          placeholder="13"
          className={classes.rgbTextField}
          type="number"
          min={0}
          max={255}
        />
        <TextField
          id="backgroundB"
          label="B"
          onChange={(val) => setB(val)}
          value={b}
          placeholder="40"
          className={classes.rgbTextField}
          type="number"
          min={0}
          max={255}
        />
      </div>
    </div>
  )
}

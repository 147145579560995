import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import { CreateDomain } from 'api-client/keystone.model'
import { Domain } from './model'

const { keystone } = ApiClient.getInstance()

export const domainsActions = ActionsSet.make<DataKeys.Domains>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.Domains,
  cacheKey: DataKeys.Domains,
})

export const listDomains = domainsActions.add(
  new ListAction<DataKeys.Domains>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to load domains')
    return keystone.getDomains()
  }).addDependency(DataKeys.ManagementTenants),
)

export const createDomain = domainsActions.add(
  new CreateAction<DataKeys.Domains>(async (body) => {
    Bugsnag.leaveBreadcrumb('Attempting to create domain')
    return keystone.createDomain((body as unknown) as CreateDomain)
  }),
)

export const updateDomain = domainsActions.add(
  new UpdateAction<DataKeys.Domains>(async ({ id, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update domain')
    return keystone.updateDomain(id as string, (body as unknown) as CreateDomain)
  }),
)

export const deleteDomain = domainsActions.add(
  new DeleteAction<DataKeys.Domains>(async (body) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete domain')
    return keystone.deleteDomain((body as unknown) as Domain)
  }),
)

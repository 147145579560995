import React, { useEffect, useState } from 'react'
import { pick } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import {
  listMngmGroups,
  deleteMngmGroup,
} from 'account/components/ssoManagement/groups/new-actions'
import { groupsSelector } from 'account/components/ssoManagement/groups/selectors'
import { routes } from 'core/utils/routes'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import DocumentMeta from 'core/components/DocumentMeta'
import ListContainer from 'core/containers/ListContainer'
import CreateUserGroupModal from './CreateUserGroupModal'
import EditUserGroupModal from './EditUserGroupModal'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import DeleteGroupModal from './DeleteGroupModal'
import DomainFilter from '../domains/DomainFilter'
import { getDomainId } from 'api-client/helpers'

type ModelDataKey = DataKeys.ManagementGroups
type SelectorModel = ArrayElement<ReturnType<typeof groupsSelector>>

const defaultParams = {}

const usePrefParams = createUsePrefParamsHook<TablePrefsParams>('UserGroups', listTablePrefs)
const searchTargets = ['name', 'description']

const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'id', label: 'UUID', display: false },
  { key: 'name', label: 'Group Name' },
  { key: 'domain', label: 'Domain' },
  { key: 'description', label: 'Description' },
]

export default function UserGroupsListPage() {
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const [selectedDomain, setSelectedDomain] = useState<string>(getDomainId())
  const [selectedGroup, setSelectedGroup] = useState<SelectorModel>(null)
  const [showEditGroupModal, setShowEditGroupModal] = useState<boolean>(false)

  const { message, loading, reload } = useListAction(listMngmGroups, {
    params: {
      domainId: selectedDomain,
    },
  })
  const data = useSelector(groupsSelector)

  useEffect(() => {
    reload(true)
  }, [selectedDomain])

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      label: 'Edit',
      icon: 'edit',
      handleAction: ([group]) => {
        setSelectedGroup(group)
        setShowEditGroupModal(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  return (
    <>
      <DocumentMeta title="Groups" />
      <DomainFilter onChange={setSelectedDomain} value={selectedDomain} />
      <CreateUserGroupModal addRoute={routes.userManagement.addGroup} domainId={selectedDomain} />
      {showEditGroupModal && (
        <EditUserGroupModal onClose={() => setShowEditGroupModal(false)} rows={[selectedGroup]} />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        label="User Groups"
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        data={data}
        getParamsUpdater={getParamsUpdater}
        addUrl={routes.userManagement.addGroup.path()}
        addText="New Group"
        // editUrl={(_, id) => routes.userManagement.editGroup.path({ id })}
        deleteAction={deleteMngmGroup}
        DeleteDialogComponent={DeleteGroupModal}
        batchActions={batchActions}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
